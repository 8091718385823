<!-- JobWidget.vue -->
<template>
  <div>
    <router-view :clientId="clientId"></router-view>
  </div>
</template>
<script>

export default {
  components: { },
  name: 'JobWidget',  
  props: {
    clientId: {
      type: String,
      required: true
    },
    widgetStyle: {
      type: Object,
      default: () => ({})
    }
    },
  mounted() {
    console.log('Fromprop', this.clientId);
  }
}

</script>

<style scoped>
/* Scoped styles for the job widget */
</style>
