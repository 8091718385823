// axios.js

import axios from 'axios';
// import Toastify from 'toastify-js'; // Make sure to install the toastify-js library
import { baseUrl } from './env';
axios.defaults.baseURL = baseUrl;

axios.interceptors.request.use(
  (config) =>
   {
    return config;
   },
  (error) =>
   {
    return Promise.reject(error);
   }
 );

 axios.interceptors.response.use (
   (response) => {
    console.log(response,'checking')
   
   
    return response;
  },
  (error) => {
    // Handle response errors
    handleError(error);
    return Promise.reject(error);
  }
);

// Handle error response
function handleError(error) {
  if (error.response && error.response.status >= 400 && error.response.status < 600) {
    // console.log(error.response.data);
    // Toastify({
    //   text: error.response.data.message,
    //   duration: 3000,
    //   style: {
    //     background: 'linear-gradient(to right, #FF6B6B, #FF2E63)',
    //   },
    // }).showToast();
  }
}

export default axios;
