import Vue from 'vue'
import Router from 'vue-router'
import JobWidgetComponent from './components/JobWidget/JobWidget-component.vue'
import NotFountPage from './components/404/notFountPage.vue'
import Nojobs from './components/NoJobsFound/Nojobs.vue'
Vue.use(Router)

const router = new Router({
    routes: [
{
  path: '/',
  name: 'JobWidget',
  component:JobWidgetComponent,
  meta: { auth: false }
},
{
  path: '/job',
  name: 'JobWidget',
  component:JobWidgetComponent,
  meta: { auth: false }
},
{
  path: '/404',
  name: 'NotFound',
  component:NotFountPage,
  meta: { auth: false }
},
{
  path: '/noJobs',
  name: 'NoJobs',
  component:Nojobs,
  meta: {auth: false}
},
{
  path: '*',
  redirect: '/404'
}
    ]
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = false; 

  if (to.meta.auth && !isAuthenticated) {
    next('/noJobs'); 
  } else {
    next();
  }
});

export default router
