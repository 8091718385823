<template>
<div class="fourofour-wrapper flexxs">

<img src="./../../assets/404/404.svg" alt=""/>

<h4>Page Not Found</h4>

<h3>Oops! It seems like you've taken a wrong turn.</h3>

<p>The page you're looking for isn't here. Don't worry, our team is on the case. Meanwhile, feel free to explore other areas of our website or contact us for further assistance. We apologize for any inconvenience caused and appreciate your understanding.</p>

</div>

</template>

<script>
export default {
  name:"NotFound",
}
</script>

<style>


.flexxs {
  display: flex;
  flex-wrap: wrap;
}
.fourofour-wrapper {
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
}

.fourofour-wrapper h4 {
  margin: 1.17rem 0 4.07rem;
  font-size: 1.25rem;
  font-weight: 400;
  text-transform: uppercase;
  background: #7D5FF9;
  background: linear-gradient(to right, #7D5FF9 0%, #0093E4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fourofour-wrapper h3 {
  font-size: 1.625rem;
  font-weight: 400;
  color: #223947;
}

.fourofour-wrapper p {
  font-size: 1.125rem;
  font-weight: 400;
  color: #828EA5;
  line-height: 2.125rem;
  text-align: center;
  margin: 1rem 0 1.5rem;
  max-width: 56rem;
}

.fourofour-wrapper a {
  display: inline-block;
  padding: 0.75rem 2.5rem;
  font-size: 1.125rem;
  font-weight: 400;
  color: #fff;
  border-radius: 0.25rem;
  background: linear-gradient(319deg, #3F5FFF 0%, #9255FD 100%);
}
</style>

  
